import React from 'react';

import Attribution from './Attribution';

function Pumpkin({ pumpkinData }) {
    if (pumpkinData && pumpkinData !== "Rate Limit Exceeded") {
        const imgURL = pumpkinData.urls.small
        return (
            <div>
                <div className="max-w-sm bg-white rounded-lg border border-gray-200 ">
                    <a href={imgURL}>
                        <img className="rounded-t-lg pumpkinBannerImg" src={imgURL} alt="pumpkin banner" style={{ maxHeight: 400 }} />
                    </a>
                    <Attribution pumpkinData={pumpkinData} />
                </div>

            </div>

        );
    } else if (pumpkinData === "Rate Limit Exceeded") {
        return (<div>API Rate Limit Exceeded</div>)
    } else {
        return (<div>No pumpkin data!</div>)
    }
}

export default Pumpkin;