/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

function TitleBar() {
    return (
        <div className="m-5 flex content-evenly titlebar">
            <div className="mr-3">
                <a href="javascript:window.location.reload(true)">
                    <img className="pumpkinBanner" src="pumpkins-400.png" alt="pumpkins" />
                </a>
            </div>
            <div className=" natParkRegular flex flex-col justify-center">
                <div className="text-2xl">
                    Pumpkin <br />
                    Preferences
                </div>
            </div>
        </div>
    );
}

export default TitleBar;