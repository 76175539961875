import React, { useEffect, useState } from 'react';
import Pumpkin from '../Pumpkin/Pumpkin';

function RandomPumpkin() {

    const [pumpkinUnSplash, setPumpkinUnSplash] = useState(null)

    useEffect(() => {
        startUp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPumpkinUnSplash = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Client-ID xYirj3VQqSFwDhHNkcANs_13KZV4AGBhW7P10Qx2tLU");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        return fetch("https://api.unsplash.com/photos/random/?query=pumpkin", requestOptions)
            .then(response => response.text())
            .then(result => {
                if (result === "Rate Limit Exceeded") {
                    return result
                } else {
                    return JSON.parse(result)
                }
            })
            .catch(error => console.log('error', error));
    }

    const startUp = async () => {
        let unSplashPumpkin = await getPumpkinUnSplash()
        console.log('unSplashPumpkin', unSplashPumpkin)
        setPumpkinUnSplash(unSplashPumpkin)
    }

    if (pumpkinUnSplash) {
        return (

            <div className="mt-5 randompumpkin"><Pumpkin pumpkinData={pumpkinUnSplash} /></div>

        );
    } else {
        return (
            <div className="mt-5 randompumpkin">Loading...</div>
        );
    }


}

export default RandomPumpkin;