
import RandomPumpkin from "./Components/RandomPumpkin/RandomPumpkin";
import TitleBar from "./Components/TitleBar/TitleBar";

function Application() {
  return (
    <main className="flex flex-col justify-center items-center">
      <TitleBar />
      <RandomPumpkin />
    </main>
  );
}

export default Application;
